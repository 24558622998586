<template>
	<div class="shiftCard" style="background: white; padding: 1.6rem; width:100%;">
    
    <Loader v-if="performingRequest" />

    <span class="flex justify-space-between">
      <span>
        <h4 v-if="userProfile && userProfile.id && (isConfirmed || isAssigned)">{{shift.name}}</h4>
    		<h3 v-if="shift && shift.position" class="mb-2">{{shift.position.title}}</h3>
      </span>
      <router-link :to="`/work/availableshifts/` + shift.id" v-if="showLink">
        <i class="fa-solid fa-2x fa-arrow-up-right" style="color:#333333;"></i>
      </router-link>
    </span>
		<div v-if="shift && shift.position" class="mb-3">${{shift.position.rate}}/hour</div>
    <div class="caption mb-3" v-if="shift && shift.position && shift.position.description">{{shift.position.description}}</div>
    <div class="caption mb-3" v-if="shift && shift.details">{{shift.details}}</div>


    <div v-if="isCancelled" class="mt-3 mb-4">
      <button class="btn btn__outlined">
        This shift has been cancelled
      </button>
    </div>

    <div v-if="isClosed || isHold" class="mt-3 mb-4">
      <button class="btn btn__outlined">
        Applications for this shift are closed
      </button>
    </div>

    <div v-if="isOpen">


    <!-- IF IS HIRED FOR SHIFT -->

      <button 
        class="btn btn__large btn__success mr-3" 
        v-if="isConfirmed">
        Confirmed <i class="fas fa-lock-alt ml-2"></i>
      </button>
      <div 
        class="caption mt-2"
        v-if="isConfirmed"
        >
        See you there!
      </div>

      <button 
        class="btn btn__large btn__success mr-3" 
        v-if="isAssigned && !isPermanent" 
        @click.once="confirm(myAssignment[0])">
        Click to Confirm Your Spot
      </button>
      <button 
        class="btn btn__large btn__success mr-3" 
        v-if="isAssigned && isPermanent" 
        @click.once="confirm(myAssignment[0])">
        Click to Confirm this Job
      </button>

     
      <!-- IF IS AVAILABLE -->
      <button :disabled="isProcessing" class="btn btn__large btn__primary" 
        v-if="userProfile && userProfile.id && isAvailable && !isNotHired && !isPending && !isHired && !isAssigned && !isConfirmedDifferentShift && !isConfirmed && !isPermanent && !isJumpBlacklist"
        @click.once="isAvailableToWork(shift.day)"
        >
        Request Shift
      </button>

      <!-- <button class="btn btn__large btn__primary" 
        v-if="userProfile && userProfile.id && isAvailable && !isNotHired && !isPending && !isHired && !isAssigned && !isConfirmedDifferentShift && !isConfirmed && !isPermanent && !isJumpBlacklist"
        @click="isApplyToGroup(shift)"
        >
        Request Shift
      </button>
 -->

      <a target="_blank" :href="`/form/` + shift.id" v-if="!userProfile || !userProfile.id">
        <button class="btn btn__primary btn__large mb-3">
          Apply Now
        </button>
      </a>


      <a :href="`/jobs/apply/` + shift.id + `/application`" v-if="userProfile && userProfile.id && !this.isPendingJob && !this.isHired && !this.isPending && isAvailable && !isAssigned && !isConfirmedDifferentShift && !isConfirmed && isPermanent && !isJumpBlacklist">
        <button class="btn btn__large btn__primary">
          Apply
        </button>
      </a>

      <!-- <button class="btn btn__large btn__primary" 
        v-if="userProfile && userProfile.id && !this.isPendingJob && !this.isPending && isAvailable && !isAssigned && !isConfirmedDifferentShift && !isConfirmed && isPermanent && !isJumpBlacklist"
        @click="applyToWork()"
        >
        Apply
      </button> -->

      <!-- <button class="btn btn__large btn__primary" 
        v-if="userProfile && userProfile.id && !this.isPendingJob && !this.isPending && isAvailable && !isAssigned && !isConfirmedDifferentShift && !isConfirmed && isPermanent && !isJumpBlacklist"
        @click="applicationModal()"

        >
        Apply
      </button>

      <transition name="modal">
        <div v-if="showModal">
          <JobApplication @close="closeModal()" />
        </div>
      </transition> -->

     <!--  <button v-if="currentUser" class="btn btn__outlined mt-3 mr-5" @click="rModal()">Payroll Help Form</button>
    <transition name="modal">
      <div v-if="payrollForm">
        <PForm @close="closeModal()" />
      </div>
    </transition> -->

      <!-- IF IS PENDING -->
      <button class="btn btn__large btn__warning" 
        v-if="isPending && !isNotHired">Application Pending
      </button>

      <button class="btn btn__large btn__warning" 
        v-if="isPendingJob && !isNotHired">Application Pending
      </button>


      <!-- <button class="btn btn__large btn__warning" 
        v-if="(isAppliedGroup || isWaitlistedGroup) && !isInGroup">Access Pending
      </button> -->

      <!-- HIRED FOR DAY -->
      <button class="btn btn__large btn__primary" v-if="isHired && !isPermanent">Hired For This Day</button>
      <button class="btn btn__large btn__primary" v-if="isHired && isPermanent">Hired For This Job</button>

      <!-- HIRED FOR DAY -->
      <div class="mt-2 caption" v-if="isHired">Your shift confirmation will be coming shortly</div>

       <!-- IF IS CONFIRMED FOR ANOTHER SHIFT -->
       <div v-if="isConfirmedDifferentShift && !isPermanent">
           <button class="btn btn__large btn__outlined">You've Been Assigned a Different Shift</button>
        </div>
        <div v-if="isConfirmedDifferentShift && isPermanent">
           <button class="btn btn__large btn__outlined">You've Been Assigned a Different Job</button>
        </div>

      <!-- NOT HIRED -->
      <button class="btn btn__large btn__danger" v-if="isNotHired && !isPermanent">Not Hired For This Shift</button>
      <button class="btn btn__large btn__danger" v-if="isNotHired && isPermanent">Not Hired For This Job</button>


      <div v-if="(isHired || isPending || isPendingJob || isAssigned) && (!userAvailableDataDay[0].confirmed || userAvailableDataDay[0].confirmed == false)" class="mt-3">

        <button class="btn btn__small btn__flat btn__outlined mr-3" 
          @click="selectItem(shift.id)" 
          v-show="activeItem != shift.id" 
          v-tooltip="'Leave a note'">
          Leave a Note <i class="ml-2 far fa-sticky-note" style="color:blue"></i>
        </button>

        <transition name="modal">
          <div v-if="activeItem == shift.id">
            <AvailabilityNote :day="shift.day" :userAvailableData="userAvailableDataDay[0]" @close="closeItem(shift.day)" />
          </div>
        </transition>

        <button class="btn btn__small btn__danger__outlined" 
          v-show="activeItem != shift.id" 
          @click.once="notAvailable(shift.day)" 
          v-tooltip="'Cancel'">
          I Can No Longer Work <i v-if="!performingRequest" class="fas fa-times ml-2"></i>
          <transition name="fade">
            <span class="ml-2" v-if="performingRequest">
            <i class="fa fa-spinner fa-spin"></i>
            </span>
          </transition>
        </button>

      </div>

    <hr class="mt-3" v-if="shift && shift.details && isConfirmed">
    <div class="strong mb-1" v-if="shift && shift.details && isConfirmed">Shift Details</div>
    <div class="caption mb-1" v-if="shift && shift.details && isConfirmed" v-html="shift.details"></div>

		<hr class="mt-3">
    <div v-if="isAssigned || isConfirmed">
		  <h5 v-if="shift && shift.event && !isPermanent">{{shift.event}}</h5>
    </div>

    </div>
  <div>
		
    <div v-if="isPermanent">
      <div class="strong mb-1">Shift Schedule</div>
      <div class="caption mb-1" v-if="shift && shift.sundayStart && shift.sundayEnd">
        Sunday: {{formatAMPM(shift.sundayStart)}} - {{formatAMPM(shift.sundayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.mondayStart && shift.mondayEnd">
        Monday: {{formatAMPM(shift.mondayStart)}} - {{formatAMPM(shift.mondayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.tuesdayStart && shift.tuesdayEnd">
        Tuesday: {{formatAMPM(shift.tuesdayStart)}} - {{formatAMPM(shift.tuesdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.wednesdayStart && shift.wednesdayEnd">
        Wednesday: {{formatAMPM(shift.wednesdayStart)}} - {{formatAMPM(shift.wednesdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.thursdayStart && shift.thursdayEnd">
        Thursday: {{formatAMPM(shift.thursdayStart)}} - {{formatAMPM(shift.thursdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.fridayStart && shift.fridayEnd">
        Friday: {{formatAMPM(shift.fridayStart)}} - {{formatAMPM(shift.fridayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.saturdayStart && shift.saturdayEnd">
        Saturday: {{formatAMPM(shift.saturdayStart)}} - {{formatAMPM(shift.saturdayEnd) | moment("h:mm:ss a") }}
      </div>

    </div>

    <div class="mb-1 strong" v-if="((shift && shift.day) && !isPermanent)">{{shift.day | moment("dddd, MMMM Do") }}</div>

    <!-- <div class="caption mb-1 strong" v-if="shift && shift.day">{{shift.day | moment("dddd, MMMM Do") }}</div> -->

    <div class="caption mb-1" v-if="shift && shift.startTime && !isPermanent">{{formatAMPM(shift.startTime) | moment("h:mm:ss a") }} - {{formatAMPM(shift.endTime) | moment("h:mm:ss a") }}</div>

		<hr>
  

    <div v-if="isAssigned || isConfirmed">
      <h5 v-if="shift && shift.venue">{{shift.venue}}</h5>
      <div class="strong caption mb-2 mt-2" v-if="shift && shift.location">
        <i class="fa-regular fa-location-dot mr-2"></i> 
        {{shift && shift.location}}
      </div>

    </div>
		<div v-else>
  		<div class="strong caption mb-2 mt-2" v-if="shift && shift.location">
  			<i class="fa-regular fa-location-dot mr-2"></i> 
        {{shift && shift.location}}
  		</div>
    </div>

    <hr v-if="shift && shift.certs && shift.certs.length >= 1">

    <div class="mb-0" v-if="shift &&  shift.position && shift.position.certs && shift.position.certs.length >= 1">
      <h5 class="mb-2">Required Certifications:</h5>
      <button class="btn__small chip mr-3 mb-1 mt-1" v-for="(item, index) in shift.position.certs">{{item.title}}</button>
    </div>

    <router-link :to="`/work/myShifts/` + shift.id" v-if="isAssigned || isConfirmed">
      <button class="btn btn__outlined-primary mt-3 mb-3">See Full Shift Details</button>
    </router-link>
    </div>

    <!-- <hr v-if="isPermanent">
    <div class="caption mb-3" v-if="isPermanent">*This is a permanent or semi-permanent position.</div> -->

    <hr v-if="shift && shift.eventInfo && shift.eventInfo.covidVax">
    <div class="caption mb-3 danger pt-1" v-if="shift && shift.eventInfo && shift.eventInfo.covidVax">*This job requires proof of COVID vaccination.</div>

    <span v-if="(isConfirmed == true) && ((shift.eventInfo.files && shift.eventInfo.files.length >= 1) || (shift.files && shift.files.length >= 1))">

      <hr class="mt-1">

      <h5 class="pb-1">Attachments</h5>
        <div v-for="(item, index) in shift.eventInfo.files" :key="index + `a`">
          <div class="flex justify-space-between align-center pt-2 pb-2" style="border-bottom:1px solid rgba(0,0,0,0.1);">
            <span>
            <div class="strong">{{item.title}}</div>
            <span class="caption">{{item.description}}</span>
            </span>
            <a :href="item.url" target="_blank">
              <button class="btn btn__outlined btn__small">
                View Attachment<i class="fas fa-external-link ml-2"></i>
              </button>
            </a>
          </div>
        </div>
        <div v-for="(item, index) in shift.files" :key="index + `a`">
          <div class="flex justify-space-between align-center pt-2 pb-2" style="border-bottom:1px solid rgba(0,0,0,0.1);">
            <span>
            <div class="strong">{{item.title}}</div>
            <span class="caption">{{item.description}}</span>
            </span>
            <a :href="item.url" target="_blank">
              <button class="btn btn__outlined btn__small">
                View Attachment<i class="fas fa-external-link ml-2"></i>
              </button>
            </a>
          </div>
        </div>
    </span>

	</div>
</template>

<script>
import { mapState } from 'vuex'
import AvailabilityNote from '@/components/AvailabilityNote.vue'
import Loader from '@/components/Loader.vue'
import JobApplication from '@/components/JobApplication.vue'
const fb = require('../../firebaseConfig.js')

export default {
	props: ['shift', 'showLink', 'assignment'],
	data: () => ({
    performingRequest: false,
    activeItem: null,
    activeApp: null,
    isProcessing: false,
    // requestedJob:{},
    activeShiftAssignments: [],
    showModal: false
  }),
  mounted() {
    this.getActiveShiftAssignments()
  },
	computed: {
    ...mapState(['currentUser', 'userProfile', 'userAvailableData', 'appliedJobs']),
    jumpBlacklist() {
      if (this.userProfile && this.userProfile.blacklist) {
        return this.userProfile.blacklist.filter(item => {
          return item.id == 'v0VY62f0WrdUxM6lodxp'
        })
      }
    },
    isJumpBlacklist() {
      if (this.jumpBlacklist && this.jumpBlacklist.length >= 1) {
        return true
      } else {
        return false
      }
    },
    isInGroup() {
      return false
    },
    userAvailableDataDay() {
      return this.userAvailableData.filter(data => {
        return (data.day == this.shift.day)
      })
    },
    myAssignment() {
      if ((this.activeShiftAssignments && this.activeShiftAssignments.length >= 1) && this.currentUser && this.currentUser.uid) {
        return this.activeShiftAssignments.filter(assignment => {
          return ((assignment.userId == this.currentUser.uid) && (assignment.shiftId == this.shift.id))
        })
      } else {
        return null
      }

    },
    // event() {
    //   return this.eventInfo
    // },
    isCancelled() {
      return this.shift.eventStatus == 'Cancelled'
    },
    isHold() {
      return this.shift.eventStatus == 'On-Hold'
    },
    isClosed() {
      return this.shift.eventStatus == 'Closed'
    },
    isOpen() {
      return (!this.shift.eventStatus || this.shift.eventStatus == 'Open')
    },
    isPermanent() {
      return this.shift && this.shift.type && (this.shift.type == 'Permanent' || this.shift.type == 'Job')
    },
    isPending() {
      if (
        !this.isPendingJob && 
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they haven't been requested
        (this.userAvailableDataDay[0].status && (this.userAvailableDataDay[0].status == 'available' || this.userAvailableDataDay[0].status == 'placed')) && 
        //they haven't been hired
        !this.userAvailableDataDay[0].dayStatus
        //they haven't been confirmed to work
        && 
        (!this.myAssignment || !this.myAssignment[0])
      ) { 
        return true
      } else {
        return false
      }
    },
    isPendingJob() {
      if (!this.isNotHired && !this.isHired && this.appliedJobs && this.appliedJobs.length >= 1 && this.appliedJobs.some((instance) => instance.shift === this.shift.id)) {
        return true
      } else {
        return false
      }
      // if (this.userAvailableDataDay && this.userAvailableDataDay[0] && this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id && this.userAvailableDataDay[0].requestedShift.type == 'Job') {
      //   return true
      // } else {
      //   return false
      // }
    },

    // !userAvailableDataDay || 
        //   userAvailableDataDay.length == 0 || 
        //   (userAvailableDataDay[0] && userAvailableDataDay[0].requestedShift && userAvailableDataDay[0].requestedShift.id != shift.id) && 
        //   (!userAvailableDataDay[0].dayStatus || userAvailableDataDay[0].dayStatus != 'not requested') &&
        //   (!myAssignment || (myAssignment && 
        //   myAssignment[0]))

    isAvailable() {
      if (
        !this.isPending && 
        //user is available to work this day
        (!this.userAvailableDataDay || !this.userAvailableDataDay[0]) || 
        //they have not already applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id != this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift != this.shift.id)) ||
        //they haven't been not requested
        (!this.userAvailableDataDay[0].dayStatus || (this.userAvailableDataDay[0].dayStatus && this.userAvailableDataDay[0].dayStatus != 'not requested'))
        //they haven't been confirmed to work
        && 
        (!this.myAssignment) 
        // &&
        // they haven't been assigned another shift
        // (this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status != "assigned")
        ) {
        return true
      } else {
        return false
      }
    },
    
    isHired() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they haven't been requested
        (this.userAvailableDataDay[0].status && (this.userAvailableDataDay[0].status == 'available' || this.userAvailableDataDay[0].status == 'placed')) && 
        //they have been hired
        (this.userAvailableDataDay && this.userAvailableDataDay[0] && this.userAvailableDataDay[0].dayStatus && this.userAvailableDataDay[0].dayStatus == 'hired')

      ) {
        return true
      } else {
        return false
      }
    },
    

    isNotHired() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they have been not hired
        ((this.userAvailableDataDay && this.userAvailableDataDay[0] && (this.userAvailableDataDay[0].dayStatus == 'not requested')))
      ) {
        return true
      } else {
        return false
      }
    },
    isAssigned() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they have been requested
        (this.userAvailableDataDay[0] && this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'assigned') && 
        //they have been been assigned this shift
        (this.myAssignment && this.myAssignment[0] && (this.myAssignment[0].shiftId == this.shift.id)) &&
        //they have not accepeted yet
        (!this.myAssignment || !this.myAssignment[0] || !this.myAssignment[0].confirmed || 
          this.myAssignment[0].confirmed == false)
      ) {
        return true
      } else {
        return false
      }
    },
    isConfirmed() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they have been requested
        (this.userAvailableDataDay[0] && this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'assigned') && 
        //they have been been assigned this shift
        (this.myAssignment && this.myAssignment[0] && (this.myAssignment[0].shiftId == this.shift.id)) &&
        //they have accepeted
        (this.myAssignment[0].confirmed)
      ) {
        return true
      } else {
        return false
      }
    },

    isConfirmedDifferentShift() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift != this.shift.id) && 
        //they have been requested
        (this.userAvailableDataDay[0] && this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'assigned') &&
        (!this.myAssignment || !this.myAssignment[0])
      ) {
        return true
      } else {
        return false
      }
    },
    // userAppCheck() {
    // 	return this.userAvailableDays.includes(this.event.days[0])
    // }
  },
  components: {
		AvailabilityNote,
		// AvailabilityNotice,
    Loader,
    JobApplication
  },
	methods: {
    signUp() {
      this.$router.push('auth/signup')
    },
    applicationModal() {
      let shift = this.shift
      // this.$emit('clicked', "open")
      this.$emit('open', shift)
      // this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    async getActiveShiftAssignments(shift) {
      if (shift && shift.id) {
        const querySnapshot = await fb.assignmentsCollection.where("shiftId", "==", shift.id).get();
        const assignmentsArray = querySnapshot.docs.map((doc) => doc.data());
        return assignmentsArray;
      }
      return [];
    },

		formatAMPM(date) {
      if (typeof date === "string") {
        const [hours, minutes] = date.split(":");
        const parsedHours = Number(hours);
        const parsedMinutes = Number(minutes);
        let ampm = "AM";

        if (parsedHours >= 12) {
          ampm = "PM";
        }

        const formattedHours = parsedHours % 12 || 12;
        const formattedMinutes = parsedMinutes < 10 ? `0${parsedMinutes}` : parsedMinutes;

        return `${formattedHours}:${formattedMinutes} ${ampm}`;
      } else if (date instanceof Date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes} ${ampm}`;
      }

      return date;
    },
    onClickChild () {
      this.activeItem = null
    },
    selectItem(iten) {
      this.activeItem = iten
    },
    closeItem(item) {
      this.activeItem = null
    },
    appInfo(day) {
      return this.userAvailableData.filter(data => {
        return (data.userId == this.currentUser.uid) && (data.day == day) && ((data.requestedShift.id || data.shift) == this.shift.id)
      })
      return this.arr = day
    },  
    // isApplyToGroup(shift) {
    //   this.performingRequest = true
    //   console.log(shift)
    //   this.$store.dispatch("applyToGroup", {
    //     group: {
    //       groupId: shift.groupIds[0],
    //     },
    //     user: this.userProfile
    //   });
    //   setTimeout(() => {
    //       this.performingRequest = false  
    //   }, 1500)
    // },
    async isApplyToGroup(shift) {
      this.performingRequest = true;
      console.log(shift);

      try {
        await this.$store.dispatch("applyToGroup", {
          group: {
            groupId: shift.groupIds[0],
          },
          user: this.userProfile
        });

        setTimeout(() => {
          this.performingRequest = false;
        }, 1500); // Adjust the timeout duration as needed
      } catch (error) {
        console.error(error);
        this.performingRequest = false;
      }
    },
    async applyToWork() {
      this.performingRequest = true;
      console.log('new job application');

      try {
        const event = {
          status: this.shift.eventInfo.status,
          title: this.shift.eventInfo.status,
          slug: this.shift.eventInfo.slug,
        };
        console.log(this.shift.type)
        await this.$store.dispatch('addJobAvailablity', {
          user: this.userProfile,
          event: event,
          shift: this.shift,
          type: this.shift.type
        });

        setTimeout(() => {
          this.performingRequest = false;
        }, 3000); // Adjust the timeout duration as needed
      } catch (error) {
        console.error(error);
        this.performingRequest = false;
      }
    },
    async isAvailableToWork(day) {
      this.isProcessing = true;

      try {
        if (
          this.userAvailableDataDay &&
          this.userAvailableDataDay[0] &&
          this.userAvailableDataDay[0].shift !== this.shift.id
        ) {
          console.log('switching');
          this.performingRequest = true;
          await this.$store.dispatch('switchAvailability', {
            user: this.userProfile,
            day: day,
            shift: this.shift,
            id: this.userAvailableDataDay[0].id,
            type: this.shift.type
          });
        } else {
          this.performingRequest = true;
          console.log('new application');
          await this.$store.dispatch('addAvailability', {
            user: this.userProfile,
            day: day,
            event: {
              status: this.shift.eventInfo.status,
              title: this.shift.eventInfo.title,
              slug: this.shift.eventInfo.slug,
              type: this.shift.type
            },
            shift: this.shift
          });
        }

        setTimeout(() => {
          this.performingRequest = false;
          this.isProcessing = false;
        }, 2000); // Adjust the timeout duration as needed
      } catch (error) {
        console.error(error);
        this.isProcessing = false;
      }
    },

    async notAvailable(day) {
      this.performingRequest = true;

      try {
        const drop = {
          userId: this.currentUser.uid,
          day: day,
          shift: this.shift
        };

        await this.$store.dispatch('dropUser', drop);

        setTimeout(() => {
          this.performingRequest = false;
        }, 2000); // Adjust the timeout duration as needed
      } catch (error) {
        console.error(error);
        this.performingRequest = false;
      }
    },

    confirm(item) {
      console.log(item)
      this.$store.dispatch('confirmFromId', item.id)
    }
	},
  beforeDestroy () {
    this.$store.dispatch('clearActiveShiftAssignments')
    // this.$store.dispatch("clearEventState")
  }
}
</script>