<template>
	<div class="modal-mask">
		<div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body pa-4" v-click-outside="onClickOutside">
		      
	          <form @submit.prevent>
	          	<div class="flex justify-space-between align-center mb-0">
	            	<h1>Job Application</h1>

  				      <button @click="$emit('close')" style="background: white;">
                 <i class="fas fa-times fa-2x"></i>
                </button>

				      </div>

              <div class="mb-3">
                <h4>Please complete the following information to apply for this job.</h4>
              </div>

              <div v-if="shift && shift.position" class="mb-2 strong">${{shift.position.rate}}/hour</div>
              <div class="mb-3" v-if="shift && shift.position && shift.position.title">
                This is an application for the position of {{shift.position.title}}.
                <div v-if="shift.position.description">{{shift.position.description}}</div>
                <div class="mt-3 strong">Location:</div>
                <div v-if="shift && shift.eventInfo && shift.eventInfo.venue">{{shift.venue}}</div>
                <div v-if="shift && shift.eventInfo.venue.shortCode">{{shift.eventInfo.venue.shortCode}}</div>
                  <div v-if="shift && shift.address">

                    <div class="mb-3">{{shift.address.street_number}} {{shift.address.street}} <span v-if="shift.address.unit">#{{shift.address.unit}}</span><br />{{shift.address.city}}, {{shift.address.state}} {{shift.address.zip}}</div>

                  </div>

                <!-- <div>{{shift.eventInfo.venue}}</div> -->

                <div v-if="shift">
                  <div class="strong">Shift Schedule</div>
                  <div  v-if="shift && shift.sundayStart && shift.sundayEnd">
                    Sunday: {{formatAMPM(shift.sundayStart)}} - {{formatAMPM(shift.sundayEnd) | moment("h:mm:ss a") }}
                  </div>
                  <div  v-if="shift && shift.mondayStart && shift.mondayEnd">
                    Monday: {{formatAMPM(shift.mondayStart)}} - {{formatAMPM(shift.mondayEnd) | moment("h:mm:ss a") }}
                  </div>
                  <div  v-if="shift && shift.tuesdayStart && shift.tuesdayEnd">
                    Tuesday: {{formatAMPM(shift.tuesdayStart)}} - {{formatAMPM(shift.tuesdayEnd) | moment("h:mm:ss a") }}
                  </div>
                  <div  v-if="shift && shift.wednesdayStart && shift.wednesdayEnd">
                    Wednesday: {{formatAMPM(shift.wednesdayStart)}} - {{formatAMPM(shift.wednesdayEnd) | moment("h:mm:ss a") }}
                  </div>
                  <div  v-if="shift && shift.thursdayStart && shift.thursdayEnd">
                    Thursday: {{formatAMPM(shift.thursdayStart)}} - {{formatAMPM(shift.thursdayEnd) | moment("h:mm:ss a") }}
                  </div>
                  <div  v-if="shift && shift.fridayStart && shift.fridayEnd">
                    Friday: {{formatAMPM(shift.fridayStart)}} - {{formatAMPM(shift.fridayEnd) | moment("h:mm:ss a") }}
                  </div>
                  <div  v-if="shift && shift.saturdayStart && shift.saturdayEnd">
                    Saturday: {{formatAMPM(shift.saturdayStart)}} - {{formatAMPM(shift.saturdayEnd) | moment("h:mm:ss a") }}
                  </div>

                </div>
              </div>
				      
	            <div class="mb-3">
                <label for="firstname">What is your first name?</label>
                <input v-model.trim="userProfile.firstName" autocomplete="given-name" type="text" placeholder="" id="firstname" required />
	            </div>
              <div class="mb-3">
                <label for="lastname">What is your last name?</label>
                <input v-model.trim="userProfile.lastName" autocomplete="given-name" type="text" placeholder="" id="lastname" required />
              </div>
	            <div class="mb-3">
	              <label for="phone">What is your cell phone number?</label>
	              <input v-model.trim="userProfile.phone" type="text" v-mask="'(###) ###-####'" placeholder="(###) ###-####" id="phone" required />
	            </div>
              <div class="mb-3">
                <label for="email">What is your email address?</label>
                <input v-model.trim="userProfile.email" autocomplete="email" type="text" placeholder="" id="email" required />
              </div>
	            <div class="mb-3">
					      <label for="dob">What is your date of birth?</label>
                <div style="width:100%;">
                  <input type="date" placeholder="" v-model.trim="userProfile.dob" id="dob" required/>
                </div>
					    </div>

              <div class="mb-3" v-if="shift && shift.eventInfo && shift.eventInfo.amazon">
                <label for="legal">Have you ever worked at an Amazon location before?</label>

                <v-select
                  v-if="userProfile && userProfile.interview"
                  label="title" 
                  :options="yesNo"
                  v-model="userProfile.interview.amazon"
                  >
                </v-select>

                 <v-select
                  v-else
                  label="title" 
                  :options="yesNo"
                  v-model="form.amazon"
                  >
                </v-select>
              </div>
             
					    <div class="mb-3">
	              <label for="legal">Are you legally allowed to work in the United States?</label>

                <v-select
                  v-if="userProfile && userProfile.interview"
                  label="title" 
                  :options="yesNo"
                  v-model="userProfile.interview.legal"
                  >
                </v-select>

                 <v-select
                  v-else
                  label="title" 
                  :options="yesNo"
                  v-model="form.legal"
                  >
                </v-select>
	            </div>
	            
              <div class="mb-3">
                <label for="standing">Are you comfortable standing and walking for long periods of time?</label>
                  <v-select
                    v-if="userProfile && userProfile.interview"
                    label="title" 
                    :options="yesNo"
                    v-model="userProfile.interview.standing"
                    >
                  </v-select>

                  <v-select
                    v-else
                    label="title" 
                    :options="yesNo"
                    v-model="form.standing"
                    >
                  </v-select>
                </div>
                <div class="mb-3">
                  <label for="forty">Are you able to lift and move a box that weighs 40lbs?</label>
                  <v-select
                    v-if="userProfile && userProfile.interview"
                    label="title" 
                    :options="yesNo"
                    v-model="userProfile.interview.forty"
                    >
                  </v-select>
                  <v-select
                    v-else
                    label="title" 
                    :options="yesNo"
                    v-model="form.forty"
                    >
                  </v-select>
                </div>
                <div class="mb-3">
                  <label for="background">Is there anything we should be aware of when we run your background check?</label>
                  <v-select
                    v-if="userProfile && userProfile.interview"
                    label="title" 
                    :options="yesNo"
                    v-model="userProfile.interview.background"
                    >
                  </v-select>
                  <v-select
                    v-else
                    label="title" 
                    :options="yesNo"
                    v-model="form.background"
                    >
                  </v-select>
                  <transition name="fade">
                    <div class="mt-3" v-if="form.background == 'Yes' || (userProfile && userProfile.interview && userProfile.interview.background && userProfile.interview.background == 'Yes')">
                      <label for="explain">Please explain:</label>
                      <input v-if="userProfile && userProfile.interview && userProfile.interview.background && userProfile.interview.background == 'Yes'" type="text" placeholder="" v-model.trim="userProfile.interview.background.explain" id="explain" />
                      <input v-else type="text" placeholder="" v-model.trim="form.explain" id="explain" />
                    </div>
                  </transition>
                </div>
                <div class="mb-3">
                  <label for="passDrugTest">Are you confident that you would pass a drug test?</label>
                  <v-select
                    v-if="userProfile && userProfile.interview"
                    label="title" 
                    :options="yesNo"
                    v-model="userProfile.interview.passDrugTest"
                    >
                  </v-select>
                  <v-select
                    v-else
                    label="title" 
                    :options="yesNo"
                    v-model="form.passDrugTest"
                    >
                  </v-select>
                </div>
                <div class="mb-3">
                  <label for="transportation">Do you have reliable transportation?</label>
                  <v-select
                    v-if="userProfile && userProfile.interview"
                    label="title" 
                    :options="yesNo"
                    v-model="userProfile.interview.transportation"
                    >
                  </v-select>
                  <v-select
                    v-else
                    label="title" 
                    :options="yesNo"
                    v-model="form.transportation"
                    >
                  </v-select>
                </div>
                <div class="mb-3">
                  <label for="nightsWeekends">Are you able to work nights or weekends?</label>
                  <v-select
                    v-if="userProfile && userProfile.interview"
                    label="title" 
                    :options="yesNoSometimes"
                    v-model="userProfile.interview.nightsWeekends"
                    >
                  </v-select>
                  <v-select
                    v-else
                    label="title" 
                    :options="yesNoSometimes"
                    v-model="form.nightsWeekends"
                    >
                  </v-select>
                </div>
                <div class="mb-3">
                  <label for="experience">Tell us why you are a good fit for this position:</label>
                  <textarea name="experience" id="" cols="30" rows="6" v-model.trim="form.experience"></textarea>
                </div>

	            <button :disabled="!valid" @click="submit()" class="btn btn__primary btn__large mt-2">Submit
	            	<transition name="fade">
                  <span class="ml-2" v-if="performingRequest">
                  <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </transition>
	            </button>
	          </form>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped type="text/css">
  .signup-form {
    width: 96%;
    max-width: 100%;
    margin: auto;
}
</style>

<script>
import { mapState } from 'vuex'
const fb = require('../firebaseConfig.js')

export default {
  props: ['shift'],
  data() {
    return {
    	performingRequest:false,
      hours: ['0-10', '10-20', '20-30', '30-40'],
      yesNo: ['Yes', 'No'],
      yesNoSometimes: ['Yes', 'No', 'Sometimes'],
      form: {},
    }
  },
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    valid() {
      if (this.userProfile && this.form && this.userProfile.firstName && this.userProfile.lastName && this.userProfile.phone && this.userProfile.email && this.userProfile.dob && (this.userProfile.interview && this.userProfile.interview.legal || this.form.legal) && (this.userProfile.interview && this.userProfile.interview.standing || this.form.standing) && (this.userProfile.interview && this.userProfile.interview.forty || this.form.forty) && (this.userProfile.interview && this.userProfile.interview.background || this.form.background) && (this.userProfile.interview && this.userProfile.interview.passDrugTest || this.form.passDrugTest) && (this.userProfile.interview && this.userProfile.interview.transportation || this.form.transportation) && (this.userProfile.interview && this.userProfile.interview.nightsWeekends || this.form.nightsWeekends)) {
        return true
      }
      else {
        return false
      }
    }
  },
  methods: {
    showAddChange() {
      this.showAddressChange = true
    },
    cancelAddChange() {
      console.log('cancelAddChange')
      this.showAddressChange = false
    },
    showChange() {
      this.showEdit = true
    },
    cancelChange() {
      this.showEdit = false
    },
  	onClickOutside (event) {
      this.$emit('close')
     },

    //  applyToWork() {
    //   this.performingRequest = true
    //   console.log('new job application')
    //   let event = {
    //     status: this.shift.eventStatus,
    //     title: this.shift.event,
    //     slug: this.shift.eventSlug,
    //   }
    //   this.$store.dispatch('addJobAvailablity', {
    //     user: this.userProfile,
    //     event: event,
    //     shift: this.shift
    //   })
    //   setTimeout(() => {
    //     this.performingRequest = false
    //   }, 3000)
    // },
    async submit() {
      this.performingRequest = true
      console.log(this.userProfile)
      console.log(this.form)
      console.log(this.shift.eventInfo)
      if (this.userProfile && this.userProfile.interview) {
        await fb.usersCollection.doc(this.userProfile.id).update({
          interview: this.userProfile.interview
        })
      }
      else {
        await fb.usersCollection.doc(this.userProfile.id).update({
          interview: this.form
        })
      }
      let event = {
        status: this.shift.eventInfo.status,
        title: this.shift.eventInfo.status,
        slug: this.shift.eventInfo.slug,
      }
      this.$store.dispatch('addJobAvailablity', {
        user: this.userProfile,
        event: event,
        shift: this.shift
      })
      setTimeout(() => {
        this.performingRequest = false
        this.form = {}
      }, 3000)
    },
    formatAMPM(date) {

      if (typeof date === "string") {
        // console.log(date)
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          if (hours == "0" || hours == "00") {
            hours = 12
          }

          ampm = "PM";
        } else {
          ampm = "AM";
        }

        if (date && (date === '00:00' || date === '0:00')) {
          return `12:00 ` + ampm
        } else {
          return `${hours}:${minutes} ${ampm}`;
        }

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;
        return strTime;

      }
      return date;
      
    },
  	
  },
}
</script>